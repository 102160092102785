import React from 'react'
import Layout from '../../components/layout/Layout'
import CrewCategoryTable from '../../components/tables/crewCategory/CrewCategoryTable'

export default function AllCrewCategory() {

  return (
    <div>
        <Layout>
            <CrewCategoryTable />
        </Layout>
    </div>
  )
}
