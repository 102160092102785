import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useTableContext } from "../../context/TableContext";
import html2pdf from "html2pdf.js";
import fng_logo from "../../assets/images/fng_logo.png";

export default function ViewBidedFieldCopy() {
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    billingType: "",
    jobAddress: "",
    jobType: "",
    crewCategory: "",
    description: "",
    crew: [],
    truckNo: "",
    trailerNo: "",
    bidingDate: "",
    status: "",
  });
  const [fieldCopies, setFieldCopies] = useState([]);
  const [jobType, setJobType] = useState("");
  const { id } = useParams();
  const [materialData, setMaterialData] = useState([]);
  const [laborData, setLaborData] = useState([]);
  const [laborTotal, setLaborTotal] = useState(0);
  const [materialsTotal, setMaterialsTotal] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  const [address, setAddress] = useState("");
  const [taxableAmount, setTaxableAmount] = useState(0);

  const navigate = useNavigate();
  const { tableSize } = useTableContext();

  const [categorizedFieldCopies, setCategorizedFieldCopies] = useState([]);

  useEffect(() => {
    let taxAmount = 0;
    if (
      categorizedFieldCopies &&
      categorizedFieldCopies[0] &&
      categorizedFieldCopies[0]?.items?.length > 0
    ) {
      for (let type of categorizedFieldCopies[0].items) {
        if (type.isTaxable) {
          taxAmount +=
            Number.parseFloat(type.price) * Number.parseFloat(type.quantity);
        }
      }
    }
    for (let labor of laborData) {
      if (labor.isLaborTaxable) {
        taxAmount += Number.parseFloat(labor.totalPrice);
      }
    }
    setTaxableAmount(taxAmount);
  }, [categorizedFieldCopies]);

  useEffect(() => {
    if (fieldCopies.length > 0) {
      const summarizedData = summarizeFieldCopies(fieldCopies);
      setCategorizedFieldCopies([
        { category: "Materials & Labor", items: summarizedData },
      ]);
      const { laborTotal, materialsTotal } = calculateTotals(fieldCopies);
      setLaborTotal(laborTotal);
      setMaterialsTotal(materialsTotal);
    }
  }, [fieldCopies]);

  useEffect(() => {
    getProjectById();
    getJobTypeById();
    getTaxPercentage();
    getBidedFieldCopyData();
    getFGAddress();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getJobTypeById();
  }, [formData]);

  const getProjectById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-project/${id}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        if (!response.data.result.isProjectStarted) {
          navigate(-1);
        }
        setFormData(response.data.result);
        setFieldCopies(response.data.result.bidedCopy);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getBidedFieldCopyData = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-bided-field-copy/${id}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setFieldCopies(response.data.result?.bidedCopiesData || []);
        setMaterialData(response.data.result.materialData || []);
        setLaborData(response.data.result.laborData || []);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getJobTypeById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      if (!formData.jobType) {
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-job-type/${formData.jobType}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setJobType(response.data.result.jobName);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getTaxPercentage = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/staff/get-tax-percent`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setTaxPercent(response.data.result.taxPercent);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const calculateTotals = (fieldCopies) => {
    const totals = {
      laborTotal: 0,
      materialsTotal: 0,
    };

    materialData.forEach((item) => {
      totals.materialsTotal += item.totalPrice;
    });

    laborData.forEach((item) => {
      totals.laborTotal += item.totalPrice;
    });

    return totals;
  };

  const summarizeFieldCopies = (fieldCopies) => {
    const summary = {};

    fieldCopies.forEach((item) => {
      const key = `${item.reference}-${item.measure}-${item.price}`;

      if (!summary[key]) {
        summary[key] = {
          source: item.source,
          isTaxable: item.isTaxable,
          reference: item.reference,
          description: item.description,
          size: item.measure,
          quantity: 0,
          price: item.price,
          totalPrice: 0,
        };
      }

      summary[key].quantity += item.quantity;
      summary[key].totalPrice += item.totalPrice;
    });

    return Object.values(summary);
  };

  const getFGAddress = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get-fg-address`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setAddress(response.data.result);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options); // Use 'en-GB' to get the desired format
  }

  const downloadPdf = () => {
    const element = document.getElementById("content-to-pdf");
    // console.log("View Bidede Field Copy");

    // Create a temporary div with the hidden content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = `
        <div class="flex justify-center mb-0">
          <img src="${fng_logo}" alt="F&G Logo" class="h-[80px]" />
        </div>
      `;

    // Insert the temporary div at the top of the content
    element.prepend(tempDiv);

    const options = {
      margin: 0.3,
      filename: "document.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      // .save()
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        // Ensure we're on the last page
        const pageCount = pdf.internal.getNumberOfPages();
        pdf.setPage(pageCount);

        // Get page dimensions
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // console.log("Page details", pageCount, pageWidth, pageHeight);

        // Add footer text
        pdf.setFontSize(10);
        pdf.text(
          `Approved by: __________________  Date: ____________________

Project Proposed on: ${
            formData?.createdAt ? formatDate(formData.createdAt) : ""
          }`,
          pageWidth / 30,
          pageHeight - 0.6,
          { align: "left" }
        );
        // pdf.text("", pageWidth/4, pageHeight-0.5, { align: "right" });

        // console.log("PDF", pdf);

        // Save the PDF with the footer added
        pdf.save("document.pdf");
      })
      .then(() => {
        // Ensure the temporary div is removed after the download completes
        tempDiv.remove();
      })
      .catch((error) => {
        console.error("PDF generation failed:", error);
        tempDiv.remove(); // Ensure cleanup even if an error occurs
      });
  };

  return (
    <Layout>
      <ToastContainer />
      <div
        className={`${tableSize === 250 ? "md:ml-[250px]" : "md:ml-[90px]"}`}
      >
        <div className="lg:p-10 p-3">
          <div className="card">
            <div className="card-header bg-[#00613e] text-white">
              <h3 className="card-title">View Bided Copy</h3>
            </div>
            {/* Downloaded copy */}
            <div className="" style={{ display: "none" }}>
              <div className="p-1" id="content-to-pdf">
                {/* Project data */}
                <div className="flex flex-row gap-3 justify-around">
                  <div className="flex flex-col w-1/3 md:w-[300px]">
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">Bidding Date</h6>
                      <p className="text-sm">
                        {formData?.createdAt
                          ? formatDate(formData.createdAt)
                          : ""}
                      </p>
                    </div>
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">Customer Name</h6>
                      <p className="text-sm">{formData?.customerName}</p>
                    </div>
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">Job Address</h6>
                      <p className="text-sm">{formData?.jobAddress}</p>
                    </div>
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">Email</h6>
                      <p className="text-sm">{formData?.customerEmail}</p>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/3 md:w-[300px]">
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">Project Code</h6>
                      <p className="text-sm">{formData.projectCode}</p>
                    </div>
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">Job Type</h6>
                      <p className="text-sm">{jobType}</p>
                    </div>
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">
                        Description of work
                      </h6>
                      <p className="text-sm">{formData.description}</p>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/3 md:w-[300px] text-end">
                    <div className="p-1">
                      <h6 className="font-bold text-[15px]">F&G INC</h6>
                      <pre
                        className="text-sm break-words p-0 pb-3"
                        style={{ fontFamily: "Source Sans Pro" }}
                      >
                        {address}
                      </pre>
                    </div>
                  </div>
                </div>

                {/* Compiled Material */}
                <div className="w-full mt-4 text-[15px] overflow-x-auto">
                  {categorizedFieldCopies.length > 0 ? (
                    categorizedFieldCopies.map((group, index) => (
                      <div key={index} className="mb-8">
                        <h4 className="font-bold text-[17px] mb-3">
                          {group.category}
                        </h4>
                        <table className="w-full text-sm text-center">
                          <thead className="">
                            <tr>
                              <th>
                                <span className="relative -top-1.5">
                                  Source
                                </span>
                              </th>
                              <th>
                                <span className="relative -top-1.5">Name</span>
                              </th>
                              <th>
                                <span className="relative -top-1.5">Size</span>
                              </th>
                              <th>
                                <span className="relative -top-1.5">
                                  Quantity
                                </span>
                              </th>
                              <th>
                                <span className="relative -top-1.5">Price</span>
                              </th>
                              <th>
                                <span className="relative -top-1.5">Total</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {group &&
                              group.items &&
                              group.items.length > 0 &&
                              group.items.map((item, idx) => (
                                <tr key={idx}>
                                  <td>{item.source}</td>
                                  <td>{item.reference}</td>
                                  <td>{item.size}</td>
                                  <td>{item.quantity || ""}</td>
                                  <td>{item.price?.toFixed(2) || ""}</td>
                                  <td>{item.totalPrice?.toFixed(2) || ""}</td>
                                </tr>
                              ))}
                            <tr>
                              <td colSpan="3" className="font-bold">
                                {/* Total: */}
                              </td>
                              <td>
                                {group.items.reduce(
                                  (acc, item) => acc + item.quantity,
                                  0
                                )}
                              </td>
                              <td>
                                {group.items
                                  .reduce((acc, item) => acc + item.price, 0)
                                  .toFixed(2)}
                              </td>
                              <td>
                                {group.items
                                  .reduce(
                                    (acc, item) => acc + item.totalPrice,
                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <p>Loading ...</p>
                  )}
                </div>

                {/* Compiled data by Job Type */}
                <div className="mt-1 text-sm">
                  {materialData.map((material) => {
                    return (
                      <div className="flex justify-between mt-1">
                        <span>
                          <b>{material.jobType} Material</b>
                        </span>
                        <span>
                          <b>$</b> {material.totalPrice?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })}
                  {laborData
                    .filter((labor) => labor.totalPrice !== 0)
                    .map((labor) => {
                      return (
                        <div className="flex justify-between mt-1">
                          <span>
                            <b>{labor.jobType} Labor</b>
                          </span>
                          <span>
                            <b>$</b> {labor.totalPrice?.toFixed(2)}
                          </span>
                        </div>
                      );
                    })}
                </div>

                {/* Invoice Summary */}
                <div className="w-full mt-2 text-[15px]">
                  <div className="">
                    <h4 className="text-[17px] font-semibold text-center mb-6">
                      Proposal Summary
                    </h4>
                  </div>
                  <div className="text-sm  bg-[whitesmoke] p-3">
                    <div className="flex justify-between">
                      <span>Total Labor Cost</span>
                      <span>
                        <b>$</b> {laborTotal?.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between my-1.5">
                      <span>Total Material Cost</span>
                      <span>
                        <b>$</b> {materialsTotal?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-1.5">
                      <span>SubTotal</span>
                      <span>
                        <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />

                    <div className="flex justify-between my-1.5">
                      <span>Taxable Amount</span>
                      <span>
                        <b>$</b> {taxableAmount?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Sales Tax</span>
                      <span>
                        <b>$</b>{" "}
                        {((taxPercent * taxableAmount) / 100)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-1.5">
                      <span>Grand Total</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * taxableAmount) / 100 +
                          (materialsTotal + laborTotal)
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Approved by */}
                {/* <div className="abosolute bottom-0 left-0 mt-4">
                  <div className="">
                    <span className="text-sm">Approved by</span>
                    <input
                      type="text"
                      className="border-b border-black ml-2 w-[150px] outline-none"
                    />
                    <span className="text-sm ml-2">Date</span>
                    <input
                      type="text"
                      className="border-b border-black ml-2 w-[150px] outline-none"
                    />
                  </div>
                  <div className="flex items-center mt-2 pb-2">
                    <h6 className="font-semibold text-[14px]">
                      PROJECT PROPOSED ON :{" "}
                    </h6>
                    <p className="text-sm">
                      {formData?.createdAt
                        ? formatDate(formData.createdAt)
                        : ""}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            {/* Real Copy */}
            <div className="mt-6 p-6 ">
              {/* Project data */}
              <div className="flex flex-col md:flex-row gap-6 justify-around">
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Bidding Date</h6>
                    <p>
                      {formData?.createdAt
                        ? formatDate(formData.createdAt)
                        : ""}
                    </p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Customer Name</h6>
                    <p>{formData?.customerName}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Job Address</h6>
                    <p>{formData?.jobAddress}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Email</h6>
                    <p>{formData?.customerEmail}</p>
                  </div>
                </div>
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Project Code</h6>
                    <p>{formData.projectCode}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Job Type</h6>
                    <p>{jobType}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">
                      Description of work
                    </h6>
                    <p>{formData.description}</p>
                  </div>
                </div>
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">F&G INC</h6>
                    <pre
                      className="text-base break-words p-0 pb-3"
                      style={{ fontFamily: "Source Sans Pro" }}
                    >
                      {address}
                    </pre>
                  </div>
                </div>
              </div>

              {/* Compiled Material */}
              <div className="w-full mt-10 text-[15px] overflow-x-auto">
                {categorizedFieldCopies.length > 0 ? (
                  categorizedFieldCopies.map((group, index) => (
                    <div key={index} className="mb-8">
                      <h4 className="font-bold text-lg mb-2">
                        {group.category}
                      </h4>
                      <table className="w-full table table-striped text-center">
                        <thead className="bg-[#00613e] text-white">
                          <tr>
                            <th>Source</th>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {group &&
                            group.items &&
                            group.items.length > 0 &&
                            group.items.map((item, idx) => (
                              <tr key={idx}>
                                <td>{item.source}</td>
                                <td>{item.reference}</td>
                                <td>{item.size}</td>
                                <td>{item.quantity || ""}</td>
                                <td>{item.price?.toFixed(2) || ""}</td>
                                <td>{item.totalPrice?.toFixed(2) || ""}</td>
                              </tr>
                            ))}
                          <tr>
                            <td colSpan="3" className="font-bold">
                              {/* Total: */}
                            </td>
                            <td>
                              {group.items.reduce(
                                (acc, item) => acc + item.quantity,
                                0
                              )}
                            </td>
                            <td>
                              {group.items
                                .reduce((acc, item) => acc + item.price, 0)
                                .toFixed(2)}
                            </td>
                            <td>
                              {group.items
                                .reduce((acc, item) => acc + item.totalPrice, 0)
                                .toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))
                ) : (
                  <p>Loading ...</p>
                )}
              </div>

              {/* Compiled data by Job Type */}
              <div className="mt-10">
                {materialData.map((material) => {
                  return (
                    <div className="flex justify-between mt-1">
                      <span>
                        <b>{material.jobType} Material</b>
                      </span>
                      <span>
                        <b>$</b> {material.totalPrice?.toFixed(2)}
                      </span>
                    </div>
                  );
                })}
                {laborData
                  .filter((labor) => labor.totalPrice !== 0)
                  .map((labor) => {
                    return (
                      <div className="flex justify-between mt-1">
                        <span>
                          <b>{labor.jobType} Labor</b>
                        </span>
                        <span>
                          <b>$</b> {labor.totalPrice?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })}
              </div>

              {/* Invoice Summary */}
              <div className="w-full mt-16 text-[15px]">
                <div className="">
                  <h4 className="text-lg font-semibold text-center mb-6">
                    Proposal Summary
                  </h4>
                </div>
                <div className="">
                  <div className="flex justify-between">
                    <span>Total Labor Cost</span>
                    <span>
                      <b>$</b> {laborTotal?.toFixed(2)}
                    </span>
                  </div>
                  <div className="flex justify-between my-2">
                    <span>Total Material Cost</span>
                    <span>
                      <b>$</b> {materialsTotal?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>SubTotal</span>
                    <span>
                      <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-1.5">
                    <span>Taxable Amount</span>
                    <span>
                      <b>$</b> {taxableAmount?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>Sales Tax</span>
                    <span>
                      <b>$</b>{" "}
                      {((taxPercent * taxableAmount) / 100)?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>Grand Total</span>
                    <span>
                      <b>$</b>{" "}
                      {(
                        (taxPercent * taxableAmount) / 100 +
                        (materialsTotal + laborTotal)
                      )?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mb-10 text-[15px] flex justify-end flex-col md:flex-row gap-4 p-6">
              {formData.status === "Ongoing" && (
                <button
                  className="bg-[#00613e] text-white py-1 px-6"
                  onClick={() => {
                    navigate(
                      `/panel/office/project/field-copy/bided/edit/${id}`
                    );
                  }}
                >
                  Edit
                </button>
              )}
              <button
                className="bg-[#00613e] text-white h-[30px] py-1  px-6 md:mr-3 mr-0"
                onClick={downloadPdf}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
