import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useTableContext } from "../../context/TableContext";
import html2pdf from "html2pdf.js";
import fng_logo from "../../assets/images/fng_logo.png";

export default function OfficeFieldCopyBidView() {
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    billingType: "",
    jobAddress: "",
    jobType: "",
    crewCategory: "",
    description: "",
    crew: [],
    truckNo: "",
    trailerNo: "",
    createdAt: "",
    status : ""
  });
  const [fieldCopies, setFieldCopies] = useState([]);
  const [bidCopies, setBidCopies] = useState([]);
  const [jobType, setJobType] = useState("");
  const { id } = useParams();
  const [laborTotal, setLaborTotal] = useState(0);
  const [materialsTotal, setMaterialsTotal] = useState(0);
  const [laborBidTotal, setLaborBidTotal] = useState(0);
  const [materialsBidTotal, setMaterialsBidTotal] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  const [address, setAddress] = useState("");
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [taxableBidAmount, setTaxableBidAmount] = useState(0);
  const [categorizedFieldCopies, setCategorizedFieldCopies] = useState([]);
  const [categorizedBidCopies, setCategorizedBidCopies] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [laborData, setLaborData] = useState([]);
  const [materialBidData, setMaterialBidData] = useState([]);
  const [laborBidData, setLaborBidData] = useState([]);
  const [bidCopiesArr, setBidCopiesArr] = useState([]);
  const [hideBidData, setHideBidData] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { tableSize } = useTableContext();

  const downloadPdf = () => {
    setHideBidData(true);

    const element = document.getElementById("content-to-pdf");

    // Create a temporary div with the hidden content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = `
    <div class="flex justify-center mb-4">
      <img src="${fng_logo}" alt="F&G Logo" class="h-[100px]" />
    </div>
  `;

    // Insert the temporary div at the top of the content
    element.prepend(tempDiv);

    const options = {
      margin: 0.5,
      filename: "document.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => {
        // Ensure the temporary div is removed after the download completes
        tempDiv.remove();
        setHideBidData(false);
      })
      .catch((error) => {
        console.error("PDF generation failed:", error);
        tempDiv.remove(); // Ensure cleanup even if an error occurs
      });
  };

  useEffect(() => {
    if (fieldCopies.length > 0) {
      const summarizedData = summarizeFieldCopies(fieldCopies);
      setCategorizedFieldCopies([
        { category: "Materials & Other", items: summarizedData },
      ]);
      const { laborTotal, materialsTotal } = calculateTotals(fieldCopies);
      setLaborTotal(laborTotal);
      setMaterialsTotal(materialsTotal);
    }
  }, [fieldCopies]);

  useEffect(() => {
    if (bidCopies.length > 0) {
      const summarizedBidData = summarizeFieldCopies(bidCopies);
      // console.log("Summary Bid copy", summarizedBidData);
      setCategorizedBidCopies([
        { category: "Bid Materials & Labor", items: summarizedBidData },
      ]);
    }
    const { laborTotal, materialsTotal } = calculateBidTotals(bidCopies);
    // console.log("labor total", laborTotal, materialsTotal);
    setLaborBidTotal(laborTotal);
    setMaterialsBidTotal(materialsTotal);
  }, [bidCopies]);

  useEffect(() => {
    let taxAmount = 0;
    if (
      categorizedFieldCopies &&
      categorizedFieldCopies[0] &&
      categorizedFieldCopies[0]?.items?.length > 0
    ) {
      for (let type of categorizedFieldCopies[0].items) {
        if (type.isTaxable) {
          taxAmount +=
            Number.parseFloat(type.price) * Number.parseFloat(type.quantity);
        }
      }
    }
    for (let labor of laborData) {
      if (labor.isLaborTaxable) {
        taxAmount += Number.parseFloat(labor.totalPrice);
      }
    }
    setTaxableAmount(taxAmount);
  }, [categorizedFieldCopies]);

  useEffect(() => {
    let taxAmount = 0;
    if (
      categorizedBidCopies &&
      categorizedBidCopies[0] &&
      categorizedBidCopies[0]?.items?.length > 0
    ) {
      for (let type of categorizedBidCopies[0].items) {
        if (type.isTaxable) {
          taxAmount +=
            Number.parseFloat(type.price) * Number.parseFloat(type.quantity);
        }
      }
    }
    for (let labor of laborBidData) {
      if (labor.isLaborTaxable) {
        taxAmount += Number.parseFloat(labor.totalPrice);
      }
    }
    setTaxableBidAmount(taxAmount);
  }, [categorizedBidCopies]);

  // useEffect(() => {
  //   let taxAmount = 0;
  //   if (
  //     categorizedBidCopies &&
  //     categorizedBidCopies[0] &&
  //     categorizedBidCopies[0]?.items?.length > 0
  //   ) {
  //     for (let type of categorizedBidCopies[0].items) {
  //       if (type.isTaxable) {
  //         taxAmount +=
  //           Number.parseFloat(type.price) * Number.parseFloat(type.quantity);
  //       }
  //     }
  //   }
  //   for (let labor of laborData) {
  //     if (labor.isLaborTaxable) {
  //       taxAmount += Number.parseFloat(labor.totalPrice);
  //     }
  //   }
  //   setTaxableAmount(taxAmount);
  // }, [categorizedBidCopies]);

  useEffect(() => {
    getProjectById();
    getJobTypeById();
    getTaxPercentage();
    getFGAddress();
    getOfficeFieldCopyData();
    getBidedFieldCopyData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getJobTypeById();
  }, [formData]);

  function formatDate(dateInput) {
    try {
      if (!dateInput) {
        return;
      }
      dateInput = Number.parseInt(dateInput, 10);
      const date = new Date(dateInput);
      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date input");
      }

      const months = [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    } catch (error) {
      console.log("Error", error);
    }
  }

  const getProjectById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-project/${id}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        if (!response.data.result.isProjectStarted) {
          navigate(-1);
        }
        setFormData(response.data.result);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getOfficeFieldCopyData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-office-field-copy/${id}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        let resultedCopies = [...response.data.result.officeFieldCopies, ...response.data.result.officeDraftCopies];
        let resultedMaterials = [...response.data.result.materialData,...response.data.result.materialDraftData];
        resultedMaterials = categorizeMaterial(resultedMaterials);
        let resultedLabors = [...response.data.result.laborData, ...response.data.result.laborDraftData];
        resultedLabors = categorizeLabor(resultedLabors);
        // console.log("-----------------------",resultedCopies)
        setFieldCopies(resultedCopies);
        setMaterialData(resultedMaterials);
        setLaborData(resultedLabors);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
    setLoading(false);
  };

  // function categorizeLabor(laborData) {
  //   return laborData.reduce((result, item) => {
  //     // Check if the jobType already exists in the result object
  //     if (!result[item.jobType]) {
  //       // Initialize a new entry for this jobType
  //       result[item.jobType] = {
  //         jobType: item.jobType,
  //         totalPrice: 0,
  //         isLaborTaxable: item.isLaborTaxable,
  //       };
  //     }
  //     // Sum up the totalPrice for the current jobType
  //     result[item.jobType].totalPrice += item.totalPrice;
  //     return result;
  //   }, {});
  // }

  // function categorizeMaterial(materialData) {
  //   return materialData.reduce((result, item) => {
  //     // Check if the jobType already exists in the result object
  //     if (!result[item.jobType]) {
  //       // Initialize a new entry for this jobType
  //       result[item.jobType] = {
  //         jobType: item.jobType,
  //         totalPrice: 0,
  //       };
  //     }
  //     // Sum up the totalPrice for the current jobType
  //     result[item.jobType].totalPrice += item.totalPrice;
  //     return result;
  //   }, {});
  // }

  function categorizeLabor(laborData) {
    const categorizedData = laborData.reduce((result, item) => {
      // Create a unique key combining jobType and isLaborTaxable to handle distinctions
      const key = `${item.jobType}-${item.isLaborTaxable}`;

      if (!result[key]) {
        // Initialize a new entry for this jobType and tax status combination
        result[key] = {
          jobType: item.jobType,
          totalPrice: 0,
          isLaborTaxable: item.isLaborTaxable,
        };
      }
      // Sum up the totalPrice for the current jobType and tax status combination
      result[key].totalPrice += item.totalPrice;
      return result;
    }, {});

    // Convert the result object to an array format
    return Object.values(categorizedData);
  }

  function categorizeMaterial(materialData) {
    const categorizedData = materialData.reduce((result, item) => {
      // Check if the jobType already exists in the result object
      if (!result[item.jobType]) {
        // Initialize a new entry for this jobType
        result[item.jobType] = {
          jobType: item.jobType,
          totalPrice: 0,
        };
      }
      // Sum up the totalPrice for the current jobType
      result[item.jobType].totalPrice += item.totalPrice;
      return result;
    }, {});

    // Convert the result object to an array format
    return Object.values(categorizedData);
  }

  const getBidedFieldCopyData = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-bided-field-copy/${id}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setBidCopies(response.data.result?.bidedCopiesData || []);
        const bidCopiesArr = response.data.result?.bidedCopiesData.map(
          (bid) => {
            return bid.reference;
          }
        );
        setBidCopiesArr(bidCopiesArr);
        setMaterialBidData(response.data.result.materialData);
        setLaborBidData(response.data.result.laborData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getFGAddress = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get-fg-address`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setAddress(response.data.result);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getJobTypeById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      if (!formData.jobType) {
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-job-type/${formData.jobType}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setJobType(response.data.result.jobName);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const calculateTotals = (fieldCopies) => {
    const totals = {
      laborTotal: 0,
      materialsTotal: 0,
    };

    materialData.forEach((item) => {
      totals.materialsTotal += item.totalPrice;
    });

    laborData.forEach((item) => {
      totals.laborTotal += item.totalPrice;
    });

    return totals;
  };

  const calculateBidTotals = (fieldCopies) => {
    const totals = {
      laborTotal: 0,
      materialsTotal: 0,
    };

    materialBidData.forEach((item) => {
      totals.materialsTotal += item.totalPrice;
    });

    laborBidData.forEach((item) => {
      totals.laborTotal += item.totalPrice;
    });

    return totals;
  };

  const getTaxPercentage = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/staff/get-tax-percent`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setTaxPercent(response.data.result.taxPercent);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const summarizeFieldCopies = (fieldCopies) => {
    const summary = {};

    fieldCopies.forEach((item) => {
      const key = `${item.reference}-${item.measure}-${item.price}`;

      if (!summary[key]) {
        summary[key] = {
          source: item.source,
          isTaxable: item.isTaxable,
          reference: item.reference,
          description: item.description,
          size: item.measure,
          quantity: 0,
          price: item.price,
          totalPrice: 0,
        };
      }

      summary[key].quantity += item.quantity;
      summary[key].totalPrice += item.totalPrice;
    });

    // Calculate the total price
    // Object.keys(summary).forEach((key) => {
    //   summary[key].totalPrice = summary[key].quantity * summary[key].price;
    // });

    return Object.values(summary);
  };

  return (
    <Layout>
      <ToastContainer />
      <div
        className={`${tableSize === 250 ? "md:ml-[250px]" : "md:ml-[90px]"}`}
      >
        <div className="lg:p-10 p-3">
          <div className="card">
            <div className="card-header bg-[#00613e] text-white">
              <h3 className="card-title">View Office Copy</h3>
            </div>
            <div className="mt-6 px-6 " id="content-to-pdf">
              {/* F&G Logo */}
              {/* <div className="hidden">
                <div className="flex justify-center">
                  <img src={fng_logo} alt="F&G Logo" className="h-[100px] " />
                </div>
              </div> */}

              {/* Project Data */}
              <div className="flex flex-col md:flex-row gap-6 justify-around mt-2">
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Date</h6>
                    <p>
                      {formData?.projectStartDate
                        ? formatDate(formData.projectStartDate)
                        : ""}
                    </p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Customer Name</h6>
                    <p>{formData?.customerName}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Job Address</h6>
                    <p>{formData?.jobAddress}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Email</h6>
                    <p>{formData?.customerEmail}</p>
                  </div>
                </div>
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Project Code</h6>
                    <p>{formData.projectCode}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Job Type</h6>
                    <p>{jobType}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">
                      Description of work
                    </h6>
                    <p>{formData.description}</p>
                  </div>
                </div>
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">F&G INC</h6>
                    <pre className="text-base break-words p-0 pb-3" style={{fontFamily : "Source Sans Pro"}}>{address}</pre>
                  </div>
                </div>
              </div>

              {/* Bid Copies Data */}
              <div className={`${hideBidData ? "hidden" : ""}`}>
                {/* Compiled data by Material */}
                <div className="w-full mt-10 text-[15px] overflow-x-scroll">
                  {categorizedBidCopies.length > 0 ? (
                    categorizedBidCopies.map((group, index) => (
                      <div key={index} className="mb-8">
                        <h4 className="font-bold text-lg mb-2">
                          {group.category}
                        </h4>
                        <table className="w-full table table-striped text-center">
                          <thead className="bg-[#00613e] text-white">
                            <tr>
                              <th>Source</th>
                              <th>Name</th>
                              <th>Size</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group &&
                              group.items &&
                              group.items.length > 0 &&
                              group.items.map((item, idx) => (
                                <tr key={idx}>
                                  <td>{item?.source}</td>
                                  <td>{item?.reference}</td>
                                  <td>{item?.size}</td>
                                  <td>{item?.quantity}</td>
                                  <td>{item?.price?.toFixed(2)}</td>
                                  <td>{item?.totalPrice?.toFixed(2)}</td>
                                </tr>
                              ))}
                            <tr>
                              <td colSpan="3" className="font-bold">
                                {/* Total: */}
                              </td>
                              <td>
                                {group.items.reduce(
                                  (acc, item) => acc + item.quantity,
                                  0
                                )}
                              </td>
                              <td>
                                {group.items
                                  .reduce((acc, item) => acc + item.price, 0)
                                  .toFixed(2)}
                              </td>
                              <td>
                                {group.items
                                  .reduce(
                                    (acc, item) => acc + item.totalPrice,
                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <p>No bid copies available.</p>
                  )}
                </div>

                {/* Compiled data by Job Type */}
                <div className="mt-10">
                  {materialBidData.map((material) => {
                    return (
                      <div className="flex justify-between mt-1">
                        <span>
                          <b>{material.jobType} Material</b>
                        </span>
                        <span>
                          <b>$</b> {material.totalPrice}
                        </span>
                      </div>
                    );
                  })}
                  {laborBidData
                    .filter((labor) => labor.totalPrice !== 0)
                    .map((labor) => {
                      return (
                        <div className="flex justify-between mt-1">
                          <span>
                            <b>{labor.jobType} Labor</b>
                          </span>
                          <span>
                            <b>$</b> {labor.totalPrice}
                          </span>
                        </div>
                      );
                    })}
                </div>

                {/* Invoice Summary */}
                <div className="w-full mt-16 text-[15px]">
                  <div className="">
                    <h4 className="text-lg font-semibold text-center mb-6">
                      Proposal Summary
                    </h4>
                  </div>
                  <div className="">
                    <div className="flex justify-between">
                      <span>Total Labor Cost</span>
                      <span>
                        <b>$</b> {laborBidTotal?.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between my-2">
                      <span>Total Material Cost</span>
                      <span>
                        <b>$</b> {materialsBidTotal?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>SubTotal</span>
                      <span>
                        <b>$</b>{" "}
                        {(materialsBidTotal + laborBidTotal)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Taxable Amount</span>
                      <span>
                        <b>$</b>{" "}
                        {((taxPercent * taxableBidAmount) / 100)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Grand Total</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * taxableBidAmount) / 100 +
                          (materialsBidTotal + laborBidTotal)
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Office Copies Data */}
              <div className="">
                {/* Compiled data by Material */}
                <div className="w-full mt-10 text-[15px] overflow-x-scroll">
                  {!loading ? categorizedFieldCopies.length > 0 ? (
                    categorizedFieldCopies.map((group, index) => (
                      <div key={index} className="mb-8">
                        <h4 className="font-bold text-lg mb-2">
                          {group.category}
                        </h4>
                        <table className="w-full table">
                          <thead>
                            <tr>
                              <th>Source</th>
                              <th>Name</th>
                              <th>Size</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group &&
                              group.items &&
                              group.items.length > 0 &&
                              group.items.map((item, idx) => (
                                <tr
                                  key={idx}
                                  className={`${
                                    bidCopiesArr.includes(item.reference)
                                      ? idx % 2 === 0
                                        ? "bg-[whitesmoke]"
                                        : "bg-[white]"
                                      : "bg-[#e0fafa]"
                                  }`}
                                >
                                  <td>{item?.source}</td>
                                  <td>{item?.reference}</td>
                                  <td>{item?.size}</td>
                                  <td>{item?.quantity}</td>
                                  <td>{item?.price?.toFixed(2)}</td>
                                  <td>{item?.totalPrice?.toFixed(2)}</td>
                                </tr>
                              ))}
                            <tr>
                              <td colSpan="3" className="font-bold">
                                {/* Total: */}
                              </td>
                              <td>
                                {group.items.reduce(
                                  (acc, item) => acc + item.quantity,
                                  0
                                )}
                              </td>
                              <td>
                                {group.items
                                  .reduce((acc, item) => acc + item.price, 0)
                                  .toFixed(2)}
                              </td>
                              <td>
                                {group.items
                                  .reduce(
                                    (acc, item) => acc + item.totalPrice,
                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <p className="pb-2">No field copies available.</p>
                  ) : <p className="pb-2">Loading ...</p>}
                </div>

                {/* Compiled data by Job Type */}
                <div className="mt-10">
                  {materialData.map((material) => {
                    return (
                      <div className="flex justify-between mt-1">
                        <span>
                          <b>{material.jobType} Material</b>
                        </span>
                        <span>
                          <b>$</b> {material.totalPrice?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })}
                  {laborData
                    .filter((labor) => labor.totalPrice !== 0)
                    .map((labor) => {
                      return (
                        <div className="flex justify-between mt-1">
                          <span>
                            <b>{labor.jobType} Labor</b>
                          </span>
                          <span>
                            <b>$</b> {labor.totalPrice?.toFixed(2)}
                          </span>
                        </div>
                      );
                    })}
                </div>

                {/* Invoice Summary */}
                <div className="w-full mt-16 text-[15px]">
                  <div className="">
                    <h4 className="text-lg font-semibold text-center mb-6">
                      Invoice Summary
                    </h4>
                  </div>
                  <div className="">
                    <div className="flex justify-between">
                      <span>Total Labor Cost</span>
                      <span>
                        <b>$</b> {laborTotal?.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between my-2">
                      <span>Total Material Cost</span>
                      <span>
                        <b>$</b> {materialsTotal?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>SubTotal</span>
                      <span>
                        <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Taxable Amount</span>
                      <span>
                        <b>$</b>{" "}
                        {((taxPercent * taxableAmount) / 100)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Grand Total</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * taxableAmount) / 100 +
                          (materialsTotal + laborTotal)
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mb-10 text-[15px] flex justify-end flex-col md:flex-row gap-4 p-4">
              <button
                className="bg-[#00613e] text-white py-1 px-6"
                // onClick={() => {
                //   navigate(`/panel/office/project/field-copy/customer/${id}`);
                // }}
                onClick={() => {
                  navigate(`/panel/office/project/customer-copy-lists/${id}`,{state : {
                    data : formData.status
                  }});
                }}
              >
                Create Customer Copy
              </button>
              <button
                className="bg-[#00613e] text-white py-1 px-6 md:mr-4 mr-0"
                onClick={downloadPdf}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
