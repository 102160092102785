import logo from './logo.svg';
import './App.css';
import Layout from './components/layout/Layout';
import AllRoutes from './allRoutes/AllRoutes';

function App() {
  return (
    <div className="wrapper">
      <AllRoutes />
    </div>
  );
}

export default App;
